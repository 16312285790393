import React from "react"
import Helmet from "react-helmet";

import { graphql } from "gatsby"

import DefaultPage from "../templates/default-page";
import Hero from "../components/hero";
import NewsAndUpdates from "../components/news-and-updates";
import Media from "../components/media";
import Social from "../components/social";
import AvailableNow from "../components/available-now";
import Footer from "../components/footer";

class IndexPage extends React.Component<IndexPageQuery, {}> {
    constructor(props: any) {
        super(props)
    }

    state = {
        isShowLetterbox: false,
        letterboxItem: [] as any,
    }

    showPhoto(item: any) {
        this.setState({ isShowLetterbox: true, letterboxItem: item })
    }

    onLetterboxClose() {
        this.setState({ isShowLetterbox: false, letterboxItem: null })
    }

    render() {
        const {news, media, assets, homepage}  = this.props.data

        const NewsItems = news.edges
        .filter(p => !p.node.frontmatter.draft)
        .slice(0, 3)
    
        const MediaItems = media.edges
        .filter(p => !p.node.frontmatter.draft)
        .slice(0, 6)

        const copy = homepage.edges[0].node.frontmatter

        return (
            <DefaultPage isIndex={true} isShowLetterbox={this.state.isShowLetterbox} onLetterboxClose={() => this.onLetterboxClose()} letterboxItem={this.state.letterboxItem}>
                <Helmet>
                    <title>Frostfall</title>
                    <meta name="description" content="Frostfall" />
                </Helmet>

                <Hero heading={copy.ctaHeading} subheading={copy.ctaSubtitle} buttonText={copy.ctaButtonText}/>
                {/* <NewsAndUpdates sectionHeading={copy.newsHeading} archiveButtonText={copy.newsArchiveButtonText} newsItems={NewsItems}/> */}
                <Social headerText={copy.socialHeading} subtitleText={copy.socialSubtitle}/>
                <Media sectionHeader={copy.mediaHeading} archiveButtonText={copy.mediaArchiveButtonText} mediaItems={MediaItems} onPhotoClicked={(item) => this.showPhoto(item)} />
                {/* <AvailableNow headerText={copy.downloadHeading} /> */}
                <Footer />
            </DefaultPage>
        )
    }
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    homepage: allMarkdownRemark(filter: {fields:{sourceName: {eq: "homepage"}}}) {
      edges {
        node {
          frontmatter {
            ctaHeading
            ctaSubtitle
            ctaButtonText
            newsHeading
            newsArchiveButtonText
            mediaHeading
            mediaArchiveButtonText
            socialHeading
            socialSubtitle
            downloadHeading
          }
        }
      }
    },
    news: allMarkdownRemark(filter: {fields:{sourceName: {eq: "news"}}}, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
            sourceName
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MM | DD | YYYY")
            path
            draft
            tags
            title
            image
            popoverContent
          }
        }
      }
    },
    media: allMarkdownRemark(filter: {fields:{sourceName: {eq: "media"}}}, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
            sourceName
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MM | DD | YYYY")
            path
            draft
            title
            image
            altText
            popoverContent
          }
        }
      }
    }, 
    standalone: allMarkdownRemark(filter: {fields:{sourceName: {eq: "standalone"}}}, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
            sourceName
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MM | DD | YYYY")
            path
            linkLabel
            popoverContent
          }
        }
      }
    }
  }
`