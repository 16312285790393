import React from "react"
import Image from './image/resourceImage'

import { appStoreLink, duskGamesLink } from "../const";

interface HeroProps {
    heading: string
    subheading: string
    buttonText: string
}

class Hero extends React.Component<HeroProps>{
    constructor(props: any) {
        super(props)
    }

    render() {
        let heading = this.props.heading;

        if (heading === 'Freeze-For-All') {
            heading = 'Freeze-<br />For-All'
        }

        return (
            <section className="c-hero o-section">
                <div className="c-hero__left">
                    <h1 className="c-hero__heading" dangerouslySetInnerHTML={{ __html: heading }}></h1>
                    <h2 className="c-hero__subheading">{this.props.subheading}</h2>
                </div>
                <div className="c-hero__right">
                    <a className="c-hero__button o-button" href="#download">{this.props.buttonText}</a>
                    <div className="container-fluid c-download-links">
                        <ul className="row c-download-links__container">
                            <li className="o-download-item col-sm-1 offset-sm-1">
                                <a href="https://apps.apple.com/us/app/frostfall/id1417448796?ls=1" target="_blank"><span className="o-download-item__icon apple">iOS</span><span className="o-download-item__url"></span></a>
                            </li>
                            <li className="o-download-item col-sm-1 offset-sm-1">
                                <a href="https://play.google.com/store/apps/details?id=com.dusk.frostfall" target="_blank"><span className="o-download-item__icon google">Android</span><span className="o-download-item__url"></span></a>
                            </li>
                        </ul>
                    </div>
                    <div className="c-hero__right-links">
                        <a className="c-dusk-link"
                            href={duskGamesLink}>Dusk Games</a>
                        {/* <a className="c-app-store-link" href={appStoreLink}>Download on the App Store</a> */}
                    </div>
                </div>
                <footer className="c-hero__footer">
                    <a className="c-dusk-link"
                        href={duskGamesLink}>Dusk Games</a>
                    {/* <a className="c-app-store-link" href={appStoreLink}>Download on the App Store</a> */}
                </footer>
                <a className="c-hero__arrow"
                    href="#media">Down</a>
                <Image className="c-hero__image-wrapper" 
                    background={{zIndex:-5, backgroundX: 'left', backgroundY: 'top'}}
                    alt="Hero Image" src="resources/images/KeyArtHeroUpdate.png"/>
            </section>
        )
    }
}

export default Hero