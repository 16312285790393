import React from "react"

interface ISocialProps {
    headerText: string
    subtitleText: string
}

class Social extends React.Component<ISocialProps>{
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <section className="c-social o-section" id="social">
                <div className="container-fluid c-social-links">
                    <ul className="row c-social-links__container">
                        {/* <li className="o-social-item col-sm-1 offset-sm-1">
                            <a href="https://facebook.com/FrostfallBR/" target="_blank"><span className="o-social-item__icon facebook">Facebook</span><span className="o-social-item__url">facebook.com/frostfall</span></a>
                        </li> */}
                        <li className="o-social-item col-sm-1 offset-sm-1">
                            <a href="https://www.youtube.com/c/FrostfallBR" target="_blank"><span className="o-social-item__icon youtube">Youtube</span><span className="o-social-item__url">youtu.be/frostfall</span></a>
                        </li>
                        <li className="o-social-item col-sm-1 offset-sm-1">
                            <a href="https://instagram.com/frostfallbr/" target="_blank"><span className="o-social-item__icon instagram">Instagram</span><span className="o-social-item__url">instagram.com/frostfall</span></a>
                        </li>
                        {/* <li className="o-social-item col-sm-1 offset-sm-1">
                            <a href="https://twitter.com/FrostfallBR" target="_blank"><span className="o-social-item__icon twitter">Twitter</span><span className="o-social-item__url">twitter.com/frostfall</span></a>
                        </li> */}
                        <li className="o-social-item col-sm-1 offset-sm-1">
                            <a href="https://discord.gg/frostfall" target="_blank"><span className="o-social-item__icon discord">Discord</span><span className="o-social-item__url">discordapp.com</span></a>
                        </li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Social